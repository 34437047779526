$modalBackgroundColor: #fff;

$inputColor: #1C2677;
$inputBackgroundColor: #F4F4F4;

$violetBlueColor: #2B5A64;

$buttonColor: #fff;
$buttonBackgroundColor: linear-gradient(90.44deg, #4F9CC7 0%, #74C4DD 100%);
$buttonModalBackgroundColor: linear-gradient(90.44deg, #4F9CC7 0%, #74C4DD 100%);
$buttonBackgroundColorHover: #fff;
$buttonBackgroundColorDisabled: linear-gradient(0deg, #19191926 0%, #19191926 100%); ;
$buttonTextColorDisabled: linear-gradient(0deg, #333333 0%, #333333 100%);

$profileButtonColor: linear-gradient(90.44deg, #47605C 0%, #47605C 100%);
$profileButtonActiveColor: #47605C;

$boxShadowGrey: rgba(197, 196, 208, 0.64);
$boxShadowPurple: rgba(84, 0, 114, 0.24);

$iconCrossSearchColor: #3d7485;
$iconResetPasswordBackgroundColor: #ffe2cc;
$iconResetPasswordFillColor: #d45c00;
$iconCoinsBalanceGradientColor: linear-gradient(90.44deg, #4F9CC7 0%, #74C4DD 100%);
$iconCoinsBalanceDefaultColor: #4F9CC7;
$iconLikeWomanProfileFillColor: #EB5757;
$iconAddPlusFillColor: #3d7485;
$iconAddPhotoFillColor: #3d7485;
$iconVideosFillColor: #4F9CC7;
$iconHandWaveFillColor: #4F9CC7;
$iconLetterFillColor: #4F9CC7;
$iconFilterFillColor: #446178;
$iconLikeVideoComponentFillColor: #3d7485;
$iconLetterVideoComponentFillColor: #3d7485;
$iconMoveFillColor: #3d7485;
$iconUploadAttachmentFillColor: #74C4DD;
$iconArrowLeftFillColor: #74C4DD;
$iconsMoreInfoChatHeaderFillColor: #4F9CC7;
$iconProfileFillColor: #3d7485;
$iconCrossCloseFillColor: #3C7384;
$iconReplyFillColor: #3C7384;

$supportFeedbackModalHeaderBgColor: rgba(79, 156, 199, 0.1);
$supportFeedbackRadioLabelColor: #06307B;
$supportFeedbackRadioLabelColorChecked: #3C7384;
$supportFeedbackRadioLabelColorHover: #3C73844D;
$supportFeedbackRatingTextColor: #446077;
$supportFeedbackTitleColor: #00317B;
$supportFeedbackTextColor: #333333;
$supportFeedbackButtonBorderHover: double 1px transparent;

$mainFont: "Open Sans", sans-serif;
$greenColor: #1C9D53;
$whiteColor: #fff;
$greyColor: #4F4F4F;
$greyBlueColor: #426076;

$inputTextColor: #334B48;

$inputBorderNormal: #F1DDD2;
$inputBorderHover: #4F9CC7;
$inputBorderActive: #4F9CC7;

$selectBackgroundColor: #E9F2F4;
$selectBorderColor: #4F9CC7;
$selectBorderHoverColor: #4F9CC7;

$textareaBackgroundColor: #FFFFFF;
$textareaBackgroundHoverColor: #F5F7FA;
$textareaBackgroundActiveColor: #FFFFFF;
$textareaBorderColor: #F1DDD2;
$textareaBorderActiveColor: #74C4DD;
$textareaOutlineColor: #74C4DD1a;

$messageRightBackground: #d1e2ee;
$messageLeftBackground: #E9F2F4;

$chatTextareaBg: #EBF2F7;

$attachTouchText: #4F9CC7;

$bluePinkColor: #4F9CC7;

$uploadTabsColor: #334B48;
$uploadTabsActiveColor: #4F9CC7;

$uploadTabsItemBg: #E7EEF4;

$uploadTabsButton: #74C4DD;

$mediaListTabActiveBg: #E9F2F4;
$mediaListTabActiveColor: #333;

$mediaListSwitcherActiveBg: #E9F2F4;

$searchBottomBtn: #4F9CC7;

$buttonHoverBg: #D1E2EE;
$buttonHoverBg2: #E9F2F4;
$buttonHoverBg3: #E9F2F4;

$lettersMenuIconColor: #47605C;

$videoButtonsColor: #4F9CC7;

$paymentBorderColor: rgba(68, 96, 119, 0.3);
$paymentBorderActiveColor: #798F8C;

$unreadLetter: #E8F1FA;

  //LOGIN MODAL
$colorLoginForgetButton: #EB5757;
$colorLoginBottom: rgba(0,0,0,.2);
$colorLoginBottomSpan: #474F5C;

//ERROR MODAL
$colorErrorTitle: #00317B;
$colorErrorTitleHref: #1952ab;

//MAIN PAGE
$buttonArrowBackground: rgba(240, 249, 255, 0.9);

//ICONS

$c1ProfileIco: url(../../img/c1-profile_btn.svg);

$c3StickerViolet: url(../../img/c3-msg-sticker-violet.svg);
$c3StickerBlue: url(../../img/c3-msg-sticker-blue.svg);

$chatHello: url(../../img/chat-hello.svg);

$profileEdit: url(../../img/c3ph-edit.svg);
$profileEditH: url(../../img/c3ph-edit-h.svg);

$profileCredits: url(../../img/c3ph-purchase.svg);
$profileCreditsH: url(../../img/c3ph-purchase-h.svg);

$profileGifts: url(../../img/c3ph-gift.svg);
$profileGiftsH: url(../../img/c3ph-gift-h.svg);

$profileContact: url(../../img/c3ph-contact.svg);
$profileContactH: url(../../img/c3ph-contact-h.svg);

$profileMeeting: url(../../img/c3ph-meet.svg);
$profileMeetingH: url(../../img/c3ph-meet-h.svg);

$profileBlock: url(../../img/block-list-p.svg);
$profileBlockH: url(../../img/block-list-p-h.svg);

$profileLogout: url(../../img/logout-p.svg);

$editProfileBtn: url(../../img/edit-profile-btn.svg);

$profileLetter: url(../img/c3wpt_mail.svg);
$profileFavs: url(../img/c3wpt_favs.svg);
$profileComplain: url(../img/c3wpt_compl.svg);
$profileGiftTop: url(../img/c3wpt_gift.svg);

$messageStatusRead: url(../../img/readed.svg);
$messageStatusSend: url(../../img/send.svg);

$paymentSelectedCheck: url(../../../../img/check-circle-full-violet.svg);

$npsRadioLabelColor: #3C7384;
$npsRadioLabelColorChecked: #3C7384;
$npsRadioLabelColorHover: #3C73844D;
$npsButtonBorderHover: double 1px transparent;
$ratingTextColor: #333333;
$npsInputBorderColor: #F1DDD2;
$npsCheckboxColor: #3C7384;
$npsContactsInputColor: #333333;
$npsTextAreaBackgroundActiveColor: #F5F7FA;
$npsModalTitleColor: #4F9CC7;

//POLICY
$policyTitleColor: #74C4DD;
$policyTextColor: #3C7384;
$policyButtonBg: #EFFBFF;
$policyLinksColor: #0000FF;
$policyLinksHoverColor: #0101D6;
$policyScrollColor: #A8B7B5;

@import 'theme';
